<template>
  <div class="TableOrder">
      <div class="case-title">
        <div class="icon_title">
          <div>
            <img class="case-title_icon" src="@/assets/images/imgAll/icon_ddtj_.png" alt />
          </div>
          <div class="case-title_max">门店商家-交易排行</div>
        </div>
      </div>
      <div>
          <phoneTable />
      </div>
  </div>
</template>
<script>
import phoneTable from './table/phoneTable'
export default {
  name: 'TableOrder',
  components:{phoneTable},
  
  data(){
      return{
      }
  },
  created(){

  },
  methods:{
     
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.TableOrder{
    width: 100%;
    min-height: 360px;
    background-color: white;
    .case-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #f6f6f6;
      .icon_title {
        display: flex;
        .case-title_icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        .case-title_max {
          display: inline-block;
          width: 200px;
          height: 17px;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

}
</style>
