<template>
  <div class="home">
    <!-- <topTitle /> -->
    <div style="color:#FF687B;font-size:14px">温馨提示: 您已承诺与您推广的合作商户签订保密协议，以下数据为商户内部交易数据，仅供您分析业务参考。未经商户同意请勿转发，泄露。如商户追责，繁星平台将不承担任何责任，如繁星平台掌握您泄露商户数据的证据，将终止与您的合作！</div>
    <div style="overflow: hidden">
      <orderModel />
    </div>
    <div class="HomeModel">
      <echartsOrder />
    </div>
    <div class="HomeModel">
      <echartsDeal />
    </div>
    <div class="HomeModel">
      <TableRecall />
    </div>
  </div>
</template>

<script>
// import topTitle from './component/topTitle'
import orderModel from "./component/orderModel";
import echartsOrder from "./component/echarts/echartsOrder";
import echartsDeal from "./component/echarts/echartsDeal";
import TableRecall from "./component/TableRecall";
import _api from "@/utils/request";
export default {
  components: {
    orderModel,
    echartsOrder,
    echartsDeal,
    TableRecall,
  },
  name: "home",
  data() {
    return {
      HomeDataList: [],
    };
  },
  created() {
    this.HomeData();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    HomeData() {
      _api.HomeData().then((res) => {
        if (res.code === 1) {
          this.HomeDataList = res.data;
          this.$store.commit("tagsView/POST_HOMEDATALIST", this.HomeDataList);
          console.log(this.HomeDataList);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.home {
  width: 100%;
  .HomeModel {
    padding-top: 20px;
  }
}
.error-page {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 8px 31px 0px rgba(20, 49, 127, 0.08);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-page-conent {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  .error-tip {
    text-align: center;
    color: #0c82ff;
    font-size: 18px;
  }
}
</style>
