<template>
  <div class="echartsOrder">
    <div class="case-title">
      <div class="icon_title">
        <div>
          <img class="case-title_icon" src="@/assets/images/imgAll/icon_ddtj_.png" alt />
        </div>
        <div class="case-title_max">成交额统计</div>
      </div>
    </div>
    <div class="echarts">
      <div class="echarts_left">
        <div class="order">
          <div class="title">本月成交总额</div>
          <div class="number">{{this.HomeDataList.monthOrderPrice}}</div>
        </div>

        <div class="order">
          <div class="title">本周成交总额</div>
          <div class="number">{{this.HomeDataList.weekOrderPrice}}</div>
        </div>
      </div>
      <div class="echarts_right">
        <div class="btnEcharts">
          <div>
            <el-button @click="dataObtain('week')">过去七天</el-button>

          </div>
          <div>
            <el-button @click="dataObtain('month')">过去三十天</el-button>
          </div>
          <div>
            <el-date-picker
              v-model="valueTime"
              type="daterange"
              range-separator="至"
              value-format="timestamp"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
          <div>
            <el-button type="primary" :loading="serchlong" @click="seachEcharts">查询</el-button>
          </div>
        </div>
        <div class="Polyline" id="chartLineBox"></div>
      </div>
    </div>
  </div>
</template>
<script>
import _api from "@/utils/request";
import moment from 'moment'
const echarts = require("echarts");
export default {
  name: "echartsOrder",
  data() {
    return {
      valueTime: "",
      startTime: "",
      endTime: "",
      dateType: "week",
      dataValueX: [],
      dataValueY: [],
      serchlong:false
    };
  },
  mounted() {
   this.echartsData()
  },
  computed: {
    HomeDataList() {
      return this.$store.state.tagsView.HomeDataList;
    }
  },
  methods: {
    echartsData() {
      this.dataValueY=[]
      this.dataValueX=[]
      if(this.startTime){
        console.log(this.startTime)
      }else{
        this.endTime = Date.parse(new Date())
        this.startTime = this.endTime - 60000*60*24*7
      }
      const vo = {
        type: "02",
        startTime: this.startTime,
        endTime: this.endTime
      };
      this.serchlong=true
      _api.echartsList(vo).then(res => {
        if (res.code === 1) {
          this.serchlong=false
          for(let i = 0; i < res.data.length; i++) {
            this.dataValueY.push(res.data[i].orderData);
            this.dataValueX.push(res.data[i].orderTime);
          }
           this.$nextTick(()=>{
            myEcharts.setOption(option)
          })
        }
      }).catch(err=>{
        this.serchlong=false
      });
      var myEcharts = echarts.init(document.getElementById("chartLineBox"));
      const option = {
        tooltip: {
          trigger: "axis"
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dataValueX
        },
        yAxis: {
          name: this.valueTime && this.valueTime.length?moment(this.valueTime[0]).format("YYYY/MM/DD")+'-'+moment(this.valueTime[1]).format("YYYY/MM/DD")+'成交额统计':this.dateType=='week'?"近七天成交额统计":this.dateType=='month'?'过去三十天成交额统计':'',
          type: "value"
        },
        series: [
          {
            name: "销售金额",
            type: "line",
            smooth: true,
            data: this.dataValueY
          }
        ]
      }
      myEcharts.setOption(option);

      
     
    },
    seachEcharts() {
      this.dateType = ""
      if (this.valueTime !== null && this.valueTime) {
        (this.startTime = this.valueTime[0]),
          (this.endTime = this.valueTime[1]);
      } else {
        this.dateType = "week"
        this.startTime = ""
        this.endTime = ""
      }
      console.log(this.startTime)
      console.log(this.endTime)

      this.echartsData();
    },
    //点击事件
    dataObtain(way) {
      this.valueTime = null
      this.endTime = Date.parse(new Date())
      if(this.dateType == way){
        return
      }
      this.dateType = way
      if(way==='week'){
        this.startTime =  this.endTime - 60000*60*24*7
        this.echartsData()
      } else{
        this.startTime = this.endTime - 60000*60*24*30
        this.echartsData()
      }
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.echartsOrder {
  width: 100%;
  height: 660px;
  background-color: white;
  .el-button.is-round {
    padding: 10px 33px !important;
  }
  .case-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #f6f6f6;
    .icon_title {
      display: flex;
      .case-title_icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      .case-title_max {
        display: inline-block;
        width: 100px;
        height: 17px;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  .echarts {
    width: 100%;
    height: 560px;
    display: flex;
    &_left {
      width: 15%;
      .order {
        margin-top: 50px;
        width: 250px;
        height: 100px;
        text-align: center;
        .title {
          margin: 0 auto;
          width: 100px;
          font-size: 14px;
          font-family: FZLanTingHei-M-GBK;
          font-weight: 400;
          color: #999999;
          line-height: 30px;
        }
        .number {
          margin: 0 auto;
          width: 100px;
          font-size: 24px;
          font-family: FZLanTingHei-M-GBK;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
      }
    }
    &_right {
      width: 80%;
      .btnEcharts {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          margin: 20px;
        }
      }
      .Polyline {
        width: 100%;
        height: 560px;
      }
    }
  }
}
</style>
