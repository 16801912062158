<template>
  <div class="orderModel">
    <div class="firstBG modelbg">
      <div class="title">
        <img class="case-title_ico" src="@/assets/images/imgAll/icon_jr_.png" alt />
      </div>
      <div class="center">
        <div class="todayOrder today">
          <div>今日订单</div>
          <div class="NumberColor_First" style="margin-left: 5px;">
             {{HomeDataList.todayOrderCount}} 
            <div
              style="
                font-size: 12px;
                margin-left: 5px;
                color: #0981ff;
                margin-top: 10px;
              "
            >
              (个)
            </div>
          </div>
        </div>
        <div class="todayMoney today">
          <div>日交易额</div>
          <div class="NumberColor_First" style="margin-left: 5px;">
             {{HomeDataList.todayOrderPrice}} 
            <div style="font-size: 12px; margin-top: 10px; color: #0981ff;margin-left: 5px">
              (元)
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="SecBG modelbg">
      <div class="title">
        <img
          class="case-title_ico"
          src="@/assets/images/imgAll/icon_zr_.png"
          alt
        />
      </div>
      <div class="center">
        <div class="todayOrder today">
          <div>昨日订单</div>
          <div class="NumberColor_Sec" style="margin-left: 5px;">
             {{HomeDataList.yesterdayOrderCount}} 
            <div
              style="
                font-size: 12px;
                margin-left: 5px;
                color: #ffaf29;
                margin-top: 10px;
              "
            >
              (个)
            </div>
          </div>
        </div>
        <div class="todayMoney today">
          <div>日交易额</div>
          <div class="NumberColor_Sec" style="margin-left: 5px;">
            {{ HomeDataList.yesterdayOrderPrice }}
            <div style="font-size: 12px; margin-top: 10px; color: #ffaf29;margin-left: 5px">
              (元)
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ThirdBG modelbg">
      <div class="title">
        <img
          class="case-title_ico"
          src="@/assets/images/imgAll/icon_bz_.png"
          alt
        />
      </div>
      <div class="center">
        <div class="todayOrder today">
          <div>本周订单</div>
          <div class="NumberColor_Third" style="margin-left: 5px;">
            {{ HomeDataList.weekOrderCount }}
            <div
              style="
                font-size: 12px;
                margin-left: 10px;
                color: #ff687b;
                margin-top: 5px;
              "
            >
              (个)
            </div>
          </div>
        </div>
        <div class="todayMoney today">
          <div>周交易额</div>
          <div class="NumberColor_Third" style="margin-left: 5px;">
            {{ HomeDataList.weekOrderPrice }}
            <div style="font-size: 12px; margin-top: 10px; color: #ff687b;margin-left: 5px">
              (元)
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fourdBG modelbg">
      <div class="title">
        <img
          class="case-title_ico"
          src="@/assets/images/imgAll/icon_by_.png"
          alt
        />
      </div>
      <div class="center">
        <div class="todayOrder today">
          <div>本月订单</div>
          <div class="NumberColor_four" style="margin-left: 5px;">
            {{ HomeDataList.monthOrderCount }}
            <div
              style="
                font-size: 12px;
                margin-left: 10px;
                color: #BB8EEF;
                margin-top: 5px;
              "
            >
              (个)
            </div>
          </div>
        </div>
        <div class="todayMoney today">
          <div>月交易额</div>
          <div class="NumberColor_four" style="margin-left: 5px;">
            {{ HomeDataList.monthOrderPrice }}
            <div style="font-size: 12px; margin-top: 10px; color: #BB8EEF;margin-left: 5px">
              (元)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "orderModel",
  data() {
    return {};
  },
  computed: {
    HomeDataList() {
      return this.$store.state.tagsView.HomeDataList;
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.orderModel {
  display: flex;
  justify-content: space-between;
  width: 102%;
  position: relative;
  padding-top: 10px;
  left: -15px;
  .modelbg {
    position: relative;
    width: 33.3%;
    height: 160px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .center {
    //   position: absolute;
    //   top: 45px;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   width: 70%;
    //   margin-left: 35%;
    padding: 20px 20px 20px 180px;
    box-sizing: border-box;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
      .today {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        div:first-child {
          height: 28px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 41px;
        }
        div:last-child {
          height: 39px;
          font-size: 46px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 41px;
        }
      }
    }
  }
  .NumberColor_First {
    color: #0981ff;
    font-size: 26px !important;
    display: flex;
  }
  .NumberColor_Sec {
    color: #ffaf29;
    font-size: 26px !important;
    display: flex;
  }
  .NumberColor_Third {
    color: #ff687b;
    font-size: 26px !important;
    display: flex;
  }
  .NumberColor_four {
    color: #BB8EEF;
    font-size: 26px !important;
    display: flex;
  }
  .firstBG {
    background-image: url("../../../assets/images/imgAll/bg_img_001@2x.png");
    background-size: 420px 160px;
    .title {
      position: absolute;
      right: 8%;
      top: 5%;
    }
  }
  .SecBG {
    background-image: url("../../../assets/images/imgAll/bg_img_002@2x.png");
    background-size: 420px 160px;
    .title {
      position: absolute;
      right: 8%;
      top: 5%;
    }
  }
  .ThirdBG {
    background-image: url("../../../assets/images/imgAll/bg_img_003@2x.png");
    background-size: 420px 160px;
    .title {
      position: absolute;
      right: 8%;
      top: 5%;
    }
  }
  .fourdBG {
    background-image: url("../../../assets/images/imgAll/bg_img_004@2x.png");
    background-size: 420px 160px;
    .title {
      position: absolute;
      right: 8%;
      top: 5%;
    }
  }
}
</style>
